.navul {
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-image: url("res/brown.jpg");
  /* background-color: rgb(93.3, 84.5, 68.2); */
  color: white;
}

.navli {
  display:inline-block;
}

.navli a {
  display:inline-block;
  color: white;
  text-align: center;
  padding: 10px 20px;
  text-decoration: none;
}

.navli a:hover {
  filter:contrast(150%);
}

.padded {
  font-family: "Times New Roman", Times, serif;
  text-align: center;
  background-image: url("res/brown.jpg");
  /* background-color: rgb(93.3, 84.5, 68.2);; */
}

.imgContainer { 
  text-align: center;
  height: 100vh;
  margin: 40px;
}

#drawing {
  max-height:70%;
  max-width:100%;
  vertical-align: middle;
}

.nameImg {
  max-width:80%;
  /* max-height:80%; */
  vertical-align: middle;
}

.navBt {
  max-height: 30px;
  min-height: 10px;
}

.about {
  font-family:comicsansms;
  font-weight: 900;
  color:yellow;
  text-shadow: -1px -1px 0 #FFA500, 1px -1px 0 #FFA500, -1px 1px 0 #FFA500, 1px 1px 0 #FFA500;
}

.scrolling-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  max-height:80%;
}

.painting {
  display: inline-block;
  max-height:75vh;
  max-width:100%;
}

#nextbtn {
  background-image: url("res/next_page.png");
  background-color: Transparent;
  border: none;
  cursor:pointer;
  width: 72px; 
  height: 54px;  
}

#prevbtn {
  background-image: url("res/prev_page.png");
  background-color: Transparent;
  border: none;
  cursor:pointer;
  width: 72px; 
  height: 54px; 
}

#nextbtn:hover {
  filter:contrast(150%);
}

#prevbtn:hover {
  filter:contrast(150%);
}

#blocker {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
}

#instructions {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-size: 14px;
  cursor: pointer;
}

.chatBox {
  position: absolute;
  left:0; bottom:0px;
  width: 60%;
  height: 40%;
  background-color: rgba(255,255,255,0.25);
  overflow:hidden;
}

#chatName {
  border: none;
  background: transparent;
  color:white;
}

#chatInput {
  border: none;
  padding: 10px;
  width: 88%;
  background: transparent;
}

#chatInput:focus {
  border: none;
}

#chatInputWrapper {
  position: absolute;
  width:100%;
  left:0px; bottom:0px;
  background: rgba(0,0,0,0.5);
}

#chatButton {
  background-color: rgba(0, 0, 0, 0.5);
  position:absolute;
  width: 10%;
  height: 100%;
  right: 0px;
  border: none;
  color: white;
}

#bodyChat {
  position: absolute;
  left:5; bottom:0px;
}
.panel-chat {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 80%;
  padding: 5px;
}

.header-chat {
  padding: 10px;
  background: rgba(0,0,0,0.75);
  color: white;
}

#instructions {
  color: white;
}

#photosBody {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  margin: 0; /* Reset margins */
  padding: 40px;
  position: relative; /* Establish a positioning context for absolute positioning */

}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns, each taking equal width */
  grid-template-rows: repeat(2, 1fr); /* 2 rows with equal height */
  gap: 10px;
  width: 70vw; /* Adjusts based on viewport width */
  position: relative;
  box-sizing: border-box; /* Ensures padding and borders don’t cause overflow */
  overflow: auto; /* Add scroll if items overflow */
}



.grid-container img {
  width: 100%;
  height: 100%; /* Ensures each image fills the grid cell */
  object-fit: contain; /* Fit the entire image within the cell */
  max-height: 100%; /* Prevents images from exceeding cell height */
  transition: all 0.5s ease;
  cursor: pointer;
}

.grid-container img.expanded {
  position: fixed; /* Changed from absolute to fixed to fill the viewport */
  top: 50%;
  left: 50%;
  width: auto;
  height: auto; /* Allow full height based on aspect ratio */
  max-height: 70vh; /* Prevent it from exceeding viewport height */
  max-width: 90vw;
  transform: translate(-50%, -50%) scale(1);
  z-index: 10; /* Bring it to the front */
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); */
  
}

.grid-container img.hidden {
  opacity: 0;
  transition: opacity 0.5s ease;
  cursor: auto;
}



.image-container {
  display: flex;
  flex-direction: column; /* Stack image and label vertically */
  align-items: center; /* Center the content horizontally */
  padding:10px;
  box-sizing: border-box; /* Includes padding/border in total height/width */
  height: 100%; 
}
.gridImage:hover {
  /* filter: contrast(1.2); */
  transform: scale(1.05);
}

.image-label {
  margin-top: 5px; /* Space between the image and label */
  font-size: 16px; /* Font size for the label */
  text-align: center; /* Center the text */
  background-image: url("res/labelbg.jpg");
  padding: 10px;
  color: #333; /* Text color */
  font-family: 'Courier New', monospace;
  font-weight: bold;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.photoFlipBtn {
  margin-top: 5px; /* Space between the image and label */
  font-size: 16px; /* Font size for the label */
  text-align: center; /* Center the text */
  background-image: url("res/labelbg.jpg");
  padding: 10px;
  color: #333; /* Text color */
  font-family: 'Courier New', monospace;
  font-weight: bold;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  border: none;
}

.button-container {
  position: fixed;
  top: calc(50% + 37vh); /* Positions it slightly below the expanded image */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center-aligns it by moving left by half its width */
  display: flex;
  justify-content: center;
  gap: 20px;
  z-index: 10; /* Same or higher z-index than the expanded image */
  border: none; /* Remove red border for final display */
}

.button-container button {
  margin: 0 10px; /* Space between the buttons */
  padding: 10px 15px; /* Button padding */
  font-size: 16px; /* Button font size */
  cursor: pointer; /* Pointer cursor on hover */
}

#about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 5vh 5vw; /* Adjust padding as desired */
  box-sizing: border-box;
}

/* Three.js canvas styling */
#about-container canvas {
  max-width: 80vw; /* Limit width to 80% of viewport width */
  /* max-height: 80vh; Limit height to 80% of viewport height */
  border: 5px solid rgb(87, 87, 87);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

#about-note {
  max-width: 100vw;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

#about-text {
  max-width: 65vw;
  padding: 120px;
  box-sizing: border-box; /* Ensures padding is included in width calculation */
  overflow: hidden; /* Enables scrolling if content exceeds max-height */
  background-image: url("res/notebg.jpg");
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  background-size: 100% auto; /* Stretches image horizontally only */
  background-repeat: repeat-y; /* Repeats the image vertically only */
  font-family: 'Courier New', monospace;
  font-weight: bold;
  font-size: 20px;
}


#storePage {
  display: flex;
  flex-direction: column; /* Stack the content vertically */
  justify-content: flex-start; /* Align items at the start (top) of the container */
  align-items: center; /* Horizontally center the content */
  padding: 20px; /* Optional: Adds padding around the content */
}


.storeItemsContainer {
  display: flex;
  gap: 20px; /* Adds space between the items */
  justify-content: center; /* Centers items horizontally */
  padding: 20px;
}

.storeItem {
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  width: 200px;
}

.storeItem img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.quantityControl {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
}

.quantityControl button {
  font-size: 18px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.quantityControl input {
  width: 50px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Medium screens (e.g., tablets) */
@media (max-width: 1024px) {
  #about-text {
    font-weight: bold;
    font-size:16px;
    padding: 40px;
  }

  .grid-container {
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    grid-template-rows: repeat(4, 1fr); /* 4 rows */
  }

  
}

/* Small screens (e.g., phones) */
@media (max-width: 600px) {
  #about-text {
    font-weight: bold;
    font-size:10px;
  }

  .grid-container {
    width: 90vw;
  }
}